

























import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class ExperienceElement extends Vue {
  @Prop() projectTitle!: string;
  @Prop() projectDescription!: string;
  @Prop() technoTitle!: string;
  @Prop() technoList!: string[];
  @Prop() tasksTitle!: string;
  @Prop() tasksList!: string[];
}
