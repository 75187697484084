import emailjs from 'emailjs-com';

export class Api {

    public async contactMe(form: HTMLFormElement) {
        const serviceID = process.env.VUE_APP_EMAIL_SERVICE_ID;
        const userID = process.env.VUE_APP_EMAIL_USER_ID;
        const templateID = process.env.VUE_APP_EMAIL_TEMPLATE_ID;

        emailjs.sendForm(serviceID, templateID, form as HTMLFormElement, userID)
        .then((result) => { console.log('SUCCESS', result.status, result.text);
    }, (error) => {
            console.error('FAILED', error);
        })
    }   
}


export const apiInstance = new Api();