









import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class EducationTitleElement extends Vue {
  @Prop() school!: string;
  @Prop() date!: string;
}
