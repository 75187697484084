import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import LanguageSelection from "@/views/LanguageSelection.vue";
import FrontEndHome from "@/views/FrontEndHome.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: Home,
    props: true,
    redirect: "/language",
    children: [
      {
        path: "/front-end/:language",
        name: "frontend",
        component: FrontEndHome,
      },
    ],
  },
  {
    path: "/language",
    name: "language",
    component: LanguageSelection,
  },
  {
    path: "/404",
    component: NotFound,
    name: "not-found",
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
