










import { Vue, Component, Watch } from "vue-property-decorator";
import MenuBar from "@/components/Front-End/Menubar.vue";
import SkillsSection from "@/components/Front-End/SkillsSection.vue";
import ExperienceSection from "@/components/Front-End/ExperienceSection.vue";
import EducationSection from "@/components/Front-End/EducationSection.vue";
import AboutSection from "@/components/Front-End/AboutSection.vue";

@Component({
  components: {
    MenuBar,
    SkillsSection,
    ExperienceSection,
    EducationSection,
    AboutSection,
  },
})
export default class FrontEndHome extends Vue {
  @Watch("$route")
  onLanguageChange() {
    this.language = this.$route.params.language;
  }

  language: string | null = null;

  created(): void {
    this.language = this.$route.params.language;
  }
}
