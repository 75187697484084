













import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class SkillElement extends Vue {
  @Prop() skillName!: string;
  @Prop() value!: string;
}
