







































































import { Vue, Component, Prop } from "vue-property-decorator";
import SkillElement from "@/components/Front-End/SkillElement.vue";

interface SkillsElements {
  name: string;
  value: string;
}

@Component({
  components: {
    SkillElement,
  },
})
export default class SkillsSection extends Vue {
  @Prop() language!: string;

  skillsGeneralElements: SkillsElements[] = [
    {
      name: "Javascript",
      value: "75",
    },
    {
      name: "Typescript",
      value: "65",
    },
    {
      name: "Python",
      value: "70",
    },
    {
      name: "Java",
      value: "50",
    },
    {
      name: "Git",
      value: "65",
    },
    {
      name: "Docker",
      value: "50",
    },
    {
      name: "CI/CD",
      value: "45",
    },
    {
      name: "Unity / C#",
      value: "50",
    },
  ];

  skillsFrontElements: SkillsElements[] = [
    {
      name: "VueJs",
      value: "80",
    },
    {
      name: "React",
      value: "30",
    },
    {
      name: "Angular",
      value: "30",
    },
    {
      name: "Html / Css",
      value: "75",
    },
    {
      name: "Android",
      value: "50",
    },
    {
      name: "REST api",
      value: "70",
    },
  ];

  skillsBackElements: SkillsElements[] = [
    {
      name: "Django",
      value: "70",
    },
    {
      name: "Database",
      value: "30",
    },
  ];

  skillsGraphElements: SkillsElements[] = [
    {
      name: "Adobe Suit",
      value: "75",
    },
    {
      name: "Figma",
      value: "60",
    },
  ];

  get skillsTitle(): string {
    return this.language === "fr"
      ? "Compétences".toUpperCase()
      : "Skills".toUpperCase();
  }

  get skillsGeneralTitle(): string {
    return this.language === "fr" ? "Développement Général" : "General dev";
  }

  get skillsFrontTitle(): string {
    return this.language === "fr" ? "Développement Front-End" : "Front End Dev";
  }

  get skillsBackTitle(): string {
    return this.language === "fr" ? "Développement Back-End" : "Back End Dev";
  }

  get skillsGraphTitle(): string {
    return this.language === "fr" ? "Graphime" : "Graphic Design";
  }
}
