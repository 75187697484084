














import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class EducationElement extends Vue {
  @Prop() language!: string;

  technoList = [
    "Html / Css / Sass",
    "Javascript",
    "REST Api",
    "Git",
    "React / Angular / VueJs",
    "Wordpress",
  ];

  descriptionFr = "Formation de développeur web";
  descriptionEn = "Web dev training";

  get learnTechnoTitle(): string {
    return this.language === "fr" ? "Technologies Apprises" : "Learned Techno";
  }

  get description(): string {
    return this.language === "fr" ? this.descriptionFr : this.descriptionEn;
  }
}
