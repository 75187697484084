
















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import HobbyElement from "@/components/Front-End/HobbyElement.vue";

@Component({
  components: {
    HobbyElement,
  },
})
export default class AboutSection extends Vue {
  @Prop() language!: string;

  aboutTextFr =
    "Afin de mieux faire connaissance, voici quelques informations supplémentaires me concernant.";

  aboutTextEn =
    "In order to get to know me better, here is some additional information about me.";

  get aboutTitle(): string {
    return this.language === "fr"
      ? "A propos".toUpperCase()
      : "About".toUpperCase();
  }

  get aboutText(): string {
    return this.language === "fr" ? this.aboutTextFr : this.aboutTextEn;
  }

  get hobbiesTitle(): string {
    return this.language === "fr" ? "Centres d'intérêts" : "Hobbies";
  }

  get family(): string {
    return this.language === "fr" ? "Composition familiale :" : "Family";
  }
}
