





































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class Menubar extends Vue {
  @Prop() language!: string;

  get about(): string {
    return this.language === "fr" ? "A propos" : "About";
  }

  get skills(): string {
    return this.language === "fr" ? "Compétences" : "Skills";
  }

  get experience(): string {
    return this.language === "fr" ? "Expérience" : "Experience";
  }

  get education(): string {
    return this.language === "fr" ? "Formation" : "Education";
  }

  get portfolio(): string {
    return "Portfolio";
  }
}
