















import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class HobbyElement extends Vue {
  @Prop() name!: string;
  @Prop() icon!: string;
  @Prop({ default: "fas" }) pack?: string;
}
