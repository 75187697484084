




















































import { Vue, Component, Prop } from "vue-property-decorator";
import ExperienceElement from "@/components/Front-End/ExperienceElement.vue";

@Component({
  components: {
    ExperienceElement,
  },
})
export default class ExperienceSection extends Vue {
  @Prop() language!: string;

  //#region project NetXR
  projectNetXRTextFr =
    "Application mobile de visualisation 3D du réseau d'égoûts souterrain en temps réel.";
  projectNetXRTextEn =
    "Mobile app helping visualize in 3D the underground sewer network in real time.";

  netXRTasksFr = [
    "Réalisation d'un protoype d'application mobile pour la partie carte 2D en Java et avec Mapbox, avec récupération de données depuis une BDD externe.",
    "Intégration de l'application de visualisation 3D faite dans Unity dans le prototype.",
    "Maintien du back-end réalisé dans Django.",
    "Mise en place d'un container Docker pour la base de donnée PostgreSQL.",
    "Réalisation de maquettes pour l'UI avec AdobeXD.",
  ];

  netXRTasksEn = [
    "Create a mobile app prototype that renders a 2D Map, with Java and Mapbox. Data fetchning from foreign database.",
    "Integration of a Unity mobile app that handles the 3D view into the prototype.",
    "Maintenance of the back end made with Django.",
    "Creation of a Docker container for the PostgreSQL database.",
    "Creation of UI mockups made with AdobeXD.",
  ];

  netXRTechno = [
    "Python / Django",
    "Java / Android",
    "Unity C#",
    "Mapbox",
    "Flutter",
    "Docker",
  ];

  get projectNetXRTitle(): string {
    return this.language === "fr" ? "Projet NetXR" : "Project NetXR";
  }

  get projectNetXRText(): string {
    return this.language === "fr"
      ? this.projectNetXRTextFr
      : this.projectNetXRTextEn;
  }

  get netXRTasks(): string[] {
    return this.language === "fr" ? this.netXRTasksFr : this.netXRTasksEn;
  }
  //#endregion

  //#region project goKnow
  projectGoKnowTextFr =
    "Application web de partages de connaissances entre ouvriers.";
  projectGoKnowTextEn =
    "Web app for sharing knowledge between factory workers.";

  goKnowTasksFr = [
    "Mise en place du front-end en VueJs.",
    "Bootstrap du back-end en Django.",
    "Mise en place d'un container Docker pour la base de donnée PostgreSQL.",
    "Réalisations de maquettes pour l'UI.",
  ];

  goKnowTechno = [
    "VueJs / VueRouter / VueX",
    "Typescript",
    "Python / Django",
    "Docker",
  ];

  goKnowTasksEn = [
    "Creation of front end with VueJs.",
    "Bootstrap back end with Django.",
    "Creation of a Docker container for the PostgreSQL database.",
    "Creation of UI mockups made with AdobeXD and Figma.",
  ];

  get projectGoKnowTitle(): string {
    return this.language === "fr" ? "Projet GoKnow" : "Project GoKnow";
  }

  get projectGoKnowText(): string {
    return this.language === "fr"
      ? this.projectGoKnowTextFr
      : this.projectGoKnowTextEn;
  }

  get goKnowTasks(): string[] {
    return this.language === "fr" ? this.goKnowTasksFr : this.goKnowTasksEn;
  }
  //#endregion

  //#region  project Divers
  projectDiversTextFr =
    "Diverses tâches qui n'ont pas été liées à un projet particulier.";
  projectDiversTextEn = "Various tasks not linked to a specific project.";

  diversTakskFr = [
    "Conversion de thèmes Bootstrap en composants VueJs.",
    "Modifications d'un panneau administrateur sur une application déjà déployée.",
    "Déploiement d'une image Docker sur un serveur Scaleway (sous supervision).",
    "Prototype d'un site web avec Intégration Continue via Gitlab.",
  ];
  diversTasksEn = [
    "Conversion of Bootstrap themes into VueJs components.",
    "Modifications of an admin panel on an already deployed application.",
    "Docker image deployment on Scaleway server (under supervision).",
    "Web site prototype with Continuous Integration on Gitlab.",
  ];

  diversTechno = ["VueJs", "Docker", "Gitlab", "Bootstrap"];

  get projectDiversTitle(): string {
    return this.language === "fr"
      ? "Réalisations diverses"
      : "Various achievements";
  }

  get projectDiversText(): string {
    return this.language === "fr"
      ? this.projectDiversTextFr
      : this.projectDiversTextEn;
  }

  get diversTasks(): string[] {
    return this.language === "fr" ? this.diversTakskFr : this.diversTasksEn;
  }
  //#endregion
  get technologies(): string {
    return this.language === "fr" ? "Technologies utilisées" : "Technologies";
  }

  get tasks(): string {
    return this.language === "fr" ? "Tâches" : "Tasks";
  }
}
