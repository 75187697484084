




















































































































import { Vue, Component, Ref } from "vue-property-decorator";
import { apiInstance } from "@/api/api";

@Component({})
export default class Sidebar extends Vue {
  language: string | null = null;

  presentationFr = `
    Après une reconversion professionnelle réussie dans le développement web et forte d'une première expérience en tant
    que Junior Software Developer, je suis à la recherche d'un nouveau défi où je pourrai m'épanouir et affûter mes connaissances.\nD'un naturel enjoué, j'aime travailler en équipe et suis curieuse de nouvelles technologies.
    `;

  presentationEn = `
    After a successful career change in web development and a first experience as a Junior Software Developer, I am looking for a new 
    challenge where I can grow and sharpen my knowledge. Of a cheerful nature, I like to work in a team and am curious about new technologies.
    `;

  loading = false;
  @Ref()
  form!: HTMLFormElement;

  get presentationText(): string {
    return this.language === "fr" ? this.presentationFr : this.presentationEn;
  }

  get jobText(): string {
    return this.language === "fr"
      ? "Développeuse Front-end Junior"
      : "Junior Front-end Developer";
  }

  get contactMeBtn(): string {
    return this.language === "fr" ? "Contactez-moi !" : "Contact me !";
  }

  emailSentStatus(status: boolean): string {
    if (status) {
      return this.language === "fr" ? "Message envoyé !" : "Message sent !";
    } else {
      return this.language === "fr"
        ? "Impossible d'envoyer le message :("
        : "Unable to send message :(";
    }
  }

  changeLanguage(language: string) {
    this.language = language;
    this.$router.push({ name: "frontend", params: { language: language } });
  }

  created(): void {
    this.language = this.$route.params.language;
  }

  async contactMe(): Promise<void> {
    this.loading = true;
    try {
      await apiInstance.contactMe(this.form);
      this.$buefy.toast.open({
        message: this.emailSentStatus(true),
        type: "is-success",
        position: "is-bottom-left",
      });
      this.form.reset();
      this.loading = false;
    } catch (e) {
      console.error(e);
      this.$buefy.toast.open({
        message: this.emailSentStatus(false),
        type: "is-danger",
        position: "is-bottom-left",
      });
    }
  }
}
