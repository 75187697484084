













































import { Vue, Component, Prop } from "vue-property-decorator";
import EducationTitleElement from "@/components/Front-End/EducationTitleElement.vue";
import EducationElement from "@/components/Front-End/EducationElement.vue";

@Component({
  components: {
    EducationTitleElement,
    EducationElement,
  },
})
export default class EducationSection extends Vue {
  @Prop() language!: string;

  get educationTitle(): string {
    return this.language === "fr" ? "Formation" : "Education";
  }
}
